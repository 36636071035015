import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { ChevronDownIcon } from '../../atoms/Icons';
import { Colors } from '../../../styles/colors';
import { H4 } from '../../../components/atoms/Typography';
import { media } from '../../../styles/media';

export const PaperCollapse = ({
  children,
  title,
  action,
  open = false,
  marginBotton = '',
  onChange,
  ...otherProps
}: React.PropsWithChildren<PaperCollapseProps>) => {
  const [openStatus, setOpen] = useState(open);
  return (
    <Container open={openStatus} {...otherProps}>
      <ContainerHeader>
        <ContainerTitle
          open={openStatus}
          onClick={() => {
            setOpen(!openStatus);
            onChange && onChange(!openStatus);
          }}
        >
          <ChevronDownIcon size={2} color={Colors.PrimaryDark} className="chevron" />
          {typeof title === 'string' ? <H4>{title}</H4> : title}
        </ContainerTitle>
        {action && <ContainerAction>{action}</ContainerAction>}
      </ContainerHeader>
      <ContainerContent open={openStatus}>{children}</ContainerContent>
    </Container>
  );
};

const Container = styled.div<Partial<PaperCollapseProps>>`
  border: 1px solid ${Colors.Primary};
  padding: 8px;
  border-radius: 4px;

  ${({ open }) =>
    open &&
    css`
      border: 1px solid ${Colors.Contrast};
    `}
`;

const ContainerHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const ContainerTitle = styled.div<Partial<PaperCollapseProps>>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  /* this will break */
  /* min-width: 100%; */

  .chevron {
    transition: transform 0.2s ease-out;
    transform: rotate(-90deg);
  }

  h4 {
    margin: 0;
  }

  ${({ open }) =>
    open &&
    css`
      .chevron {
        color: ${Colors.Contrast};
        transform: rotate(0deg);
      }
    `}
`;

const ContainerAction = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  gap: 8px;

  ${media.from_tablet`
    justify-content: flex-end;
  `}
`;

const ContainerContent = styled.div<Partial<PaperCollapseProps>>`
  opacity: 0;
  height: 0;
  transition: opacity 1s ease-out;
  overflow: hidden;

  ${({ open }) =>
    open &&
    css`
      opacity: 1;
      height: auto;
    `}
`;
interface PaperCollapseProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string | React.ReactElement;
  open?: boolean;
  marginBotton?: string;
  action?: React.ReactElement;
  onChange?: (open: boolean) => void;
}
